@import 'colors';

.color-g300 {
  color: $green-g300 !important;
}
.color-n400 {
  color: $neutral-n400 !important;
}
.color-n500 {
  color: $neutral-n500 !important;
}
.color-n600 {
  color: $neutral-n600 !important;
}
.color-r200 {
  color: $red-r200 !important;
}
.color-r300 {
  color: $red-r300 !important;
}
.color-o300 {
  color: $orange-o300 !important;
}
.color-light-orange {
  color: $light-orange !important;
}
.color-white {
  color: $white !important;
}
.color-p100 {
  color: $purple-p100 !important;
}
.color-p200 {
  color: $purple-p200 !important;
}

.gap-0 {
    gap: 0rem !important;
}
.gap-1 {
    gap: 0.25rem !important;
}
.gap-2 {
    gap: 0.5rem !important;
}
.gap-3 {
    gap: 1rem !important;
}
.gap-4 {
    gap: 1.5rem !important;
}
.gap-5 {
    gap: 3rem !important;
}

.column-gap-0 {
    column-gap: 0rem !important;
}
.column-gap-1 {
    column-gap: 0.25rem !important;
}
.column-gap-2 {
    column-gap: 0.5rem !important;
}
.column-gap-3 {
    column-gap: 1rem !important;
}
.column-gap-4 {
    column-gap: 1.5rem !important;
}
.column-gap-5 {
    column-gap: 3rem !important;
}
.column-gap-18 {
    column-gap: 18px !important;
}
.column-gap-20 {
    column-gap: 20px !important;
}
  
.row-gap-0 {
    row-gap: 0rem !important;
}
.row-gap-1 {
    row-gap: 0.25rem !important;
}
.row-gap-2 {
    row-gap: 0.5rem !important;
}
.row-gap-3 {
    row-gap: 1rem !important;
}
.row-gap-4 {
    row-gap: 1.5rem !important;
}
.row-gap-5 {
    row-gap: 3rem !important;
}
.row-gap-18 {
    row-gap: 18px !important;
}
.row-gap-20 {
    row-gap: 20px !important;
}
html {
    font-family: Dubai Regular !important;
    font-weight: lighter;
    color: $black;
    line-height: $baseLineHeight;
    font-size: 1rem;
    body {
        font-family: inherit;
        font-weight: 300;
    }
  }
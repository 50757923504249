// ----------------- OpenSans Font types --------------------------------

@include font-face(
    'OpenSans-Light',
    "../../assets/fonts/OpenSans-Light",
    300
);
@include font-face(
    'OpenSans-Regular',
    "../../assets/fonts/OpenSans-Regular"
);
@include font-face(
    'OpenSans-Bold',
    "../../assets/fonts/OpenSans-Bold",
    bold
);

// ------------------ OpenSans Font variables   -------------------------

$openSans-light: 'OpenSans-Light';
$openSans-regular: 'OpenSans-Regular';
$openSans-bold: 'OpenSans-Bold';

@import 'colors';

.bg-n100 {
  background-color: $neutral-n100;
}
.bg-n200 {
  background-color: $neutral-n200;
}
.bg-n300 {
  background-color: $neutral-n300;
}
.bg-n500 {
  background-color: $neutral-n500;
}
.bg-n600 {
  background-color: $neutral-n600;
}
.bg-rose {
  background-color: $bg-rose !important;
}
.bg-c100 {
  background-color: $cyan-c100 !important;
}
.bg-r100 {
  background-color: $red-r100 !important;
}
.bg-r200 {
  background-color: $red-r200 !important;
}
.bg-o100 {
  background-color: $orange-o100 !important;
}
.bg-g100 {
  background-color: $green-g100 !important;
}
.bg-p100 {
  background-color: $purple-p100 !important;
}
.bg-p200 {
  background-color: $purple-p200 !important;
}
.bg-light-purple {
  background-color: $light-purple-bg !important;
}

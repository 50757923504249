.mt-05 {
  margin-top: 2px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-10 {
  margin-left: 10px !important;
}
.mt-35 {
  margin-top: 35px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mt-55 {
  margin-top: 55px;
}
.mb-20 {
  margin-bottom: 20px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

// Minus
.mt--1 {
  margin-top: -0.25rem;
}
.mt--2 {
  margin-top: -0.5rem;
}
.mt--3 {
  margin-top: -1rem;
}
.mt--4 {
  margin-top: -1.5rem;
}
.mt--5 {
  margin-top: -3rem;
}

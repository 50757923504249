.semi-bold,
.font-weight-500,
.font-weight-400 {
  font-family: $openSans-regular;
}
.font-bold {
  font-family: $openSans-bold !important;
  font-weight: bold !important;
}
.semi-bold {
  font-weight: 600 !important;
}
.font-weight-500 {
  font-family: $openSans-regular;
  font-weight: 500 !important;
}
.font-weight-400 {
  font-family: $openSans-regular;
  font-weight: 400 !important;
}

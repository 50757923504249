/* You can add global styles to this file, and also import other style files */

@import 'lightgallery/scss/lightgallery';
@import 'app/scss/__abstract';
@import 'app/scss/spinner';
@import 'app/scss/btn-spinner';
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

.hide-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.row-gap-3 {
  row-gap: 1rem;
}
.column-gap-3 {
  column-gap: 1rem;
}
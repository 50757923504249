@import 'colors';

.button-n600 {
  font-family: $openSans-bold;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $neutral-n600;
}

.body-n400,
.body-n600 {
  font-size: 14px;
  font-family: $openSans-regular;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.body-n400 {
  color: $neutral-n400;
}

.body-n600 {
  color: $neutral-n600;
}

.display-large-desktop,
.display-medium-desktop,
.display-small-desktop,
.section-heading-desktop,
.page-heading-desktop,
.body-desktop,
.subheading,
.caption-desktop {
  font-family: $openSans-regular;
}
.section-title {
  font-family: 22px !important;
}
.display-large-desktop {
  font-size: 28px;
  line-height: 1.14;
}
.display-medium-desktop {
  font-size: 26px;
  line-height: 1.23;
}
.display-small-desktop {
  font-size: 20px;
  line-height: 1.4;
}
.section-heading-desktop {
  font-size: 16px;
  line-height: 1.5;
}
.page-heading-desktop {
  font-size: 20px;
  line-height: 1.2;
}
.body-desktop {
  font-size: 14px !important;
  line-height: 1.43;
}
.button-desktop {
  font-size: 14px !important;
  line-height: 1.14;
  cursor: pointer;
  text-decoration: underline;
}
.subheading {
  font-size: 12px;
  line-height: 16px;
}
.caption-desktop {
  font-size: 12px;
  line-height: 14px;
}


$white: #fff;
$light-orange: #e1b878;
$light-orange-100: #fff5ea;
$link-color: #2b6fcb;
$light-purple-bg: #eeebf7;
$bg-rose: #f7f3fb;
$dark-green: #004b28;
$neutral-n600: #1c283b;
$neutral-n500: #23334a;
$neutral-n400: #5a6b80;
$neutral-n300: #d3d6da;
$neutral-n100: #f6f9fc;
$neutral-n200: #e9ebed;
$green-g100: #e5f9f0;
$green-g300: #2a6d4f;
$cyan-c100: #def7f6;
$red-r100: #fbefef;
$red-r200: #ca1e1e;
$red-r300: #640e0e;
$orange-o100: #feeee6;
$orange-o300: #955836;
$purple-p100: #e7ddf3;
$purple-p200: #641bb4;

@import '../../../node_modules/ng-uikit-pro-standard/assets/scss/bootstrap/bootstrap-grid.scss';

/* backgrounds */

.azure {
  background-color: $azure !important;
}

.red-material {
  background-color: $red-material !important;
}

.yellow-orange {
  background-color: $yellow-orange !important;
}

.purple-blue {
  background-color: $purple-blue !important;
}

.dark {
  background-color: $dark !important;
}

.netural-n100 {
  background-color: $netural-n100 !important;
}

.netural-n400-color {
  color: $netural-n400 !important;
}

.butterscotch {
  background-color: $butterscotch !important;
}

.aqua-marine {
  background-color: $aqua-marine !important;
}

.greyish {
  background-color: $greyish !important;
}

.purple-blue-two {
  background-color: $purple-blue-two !important;
}

.dark-indigo {
  background-color: $dark-indigo !important;
}

.light-indigo {
  background-color: $light-indigo !important;
}

.green-blue {
  background-color: $green-blue !important;
}

.white-grey {
  background-color: $white-grey !important;
}

.green-blue-2 {
  background-color: $green-blue-2 !important;
}

.white-bg {
  background-color: white !important;
}

.bg-sweet-grey {
  background-color: $sweet-grey !important;
}

.light-grey {
  background-color: $light-grey !important;
}

/* buttons */

.button-rounded {
  width: auto;
  height: auto;
  border-radius: 25px;
  color: white !important;
  text-align: center;
}

/* shadows */

.purple-shadow {
  box-shadow: 0 3px 6px 0 rgba(117, 95, 155, 0.62);
}

.blue-shadow {
  box-shadow: 0 3px 6px 0 rgba(50, 130, 190, 0);
}

/* font colors */

.azure-color {
  color: $azure;
}

.red-material-color {
  color: $red-material;
}

.yellow-orange-color {
  color: $yellow-orange;
}

.purple-blue-color {
  color: $purple-blue !important;
}

.butterscotch-color {
  color: $butterscotch;
}

.aqua-marine-color {
  color: $aqua-marine;
}

.greyish-color {
  color: $greyish;
}

.purple-blue-two-color {
  color: $purple-blue-two;
}

.dark-indigo-color {
  color: $dark-indigo;
}

.light-indigo-color {
  color: $light-indigo;
}

.green-blue-color {
  color: $green-blue;
}

.white-grey-color {
  color: $white-grey;
}

.warm-grey-color {
  color: $warm-grey;
}

.bit-dark-grey-color {
  color: $bit-dark-grey;
}

.white-color {
  color: white !important;
}

.green-blue-2-color {
  color: $green-blue-2 !important;
}

.invalid-feedback {
  display: inline;
  font-size: 1rem;
}

.top-5-auto {
  margin: 5% auto;
}

.font-30 {
  font-size: 30px;
}

.font-18 {
  font-size: 18px;
  line-height: normal;
}

.font-dubai {
  font-family: $dubai-reg;
}

.half-rounded-button {
  position: absolute;
  text-align: center;
  height: 45px;
  border: none;
}

.left-button {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.right-button {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.absolute-left {
  left: 0 !important;
}

.absolute-right {
  right: 0 !important;
}

.relative {
  position: relative;
}

.blue-hover {
  &:hover,
  &:hover * {
    background-color: $azure !important;
    color: white !important;

    .purple-border {
      border: solid 2px white;
      padding: 5px;
      background-color: white !important;
    }
  }
}

.blue-selected,
.blue-selected * {
  background-color: $azure !important;
  color: white !important;

  .purple-border {
    border: solid 2px white;
    padding: 5px;
    background-color: white !important;
  }
}

.purple-hover {
  &:hover,
  &:hover * {
    background-color: $purple-blue !important;
    color: white !important;
    cursor: pointer;

    .purple-border {
      border: solid 2px white;
      padding: 5px;
      background-color: white !important;
    }
  }
}

.purple-selected,
.purple-selected * {
  background-color: $purple-blue !important;
  color: white !important;

  .purple-border {
    border: solid 2px white;
    padding: 5px;
    background-color: white !important;
  }
}

.purple-border {
  border: solid 2px #b2b2b2;
  padding: 5px;
}

.border-2 {
  border: solid 2px #d3d6da;
}

.red-hover {
  &:hover,
  &:hover * {
    color: #ff3b5b !important;
    cursor: pointer;
  }
}

.hover-component {
  cursor: pointer;
}

.text-size-300 {
  font-size: 3rem !important;
}

.text-size-200 {
  font-size: 2rem !important;
}

.text-size-175 {
  font-size: 1.75rem !important;
}

.text-size-150 {
  font-size: 1.5rem !important;
}

.text-size-125 {
  font-size: 1.25rem !important;
}

.text-size-1125 {
  font-size: 1.125rem !important;
}

.text-size-75 {
  font-size: 0.75rem !important;
}

.text-size-50 {
  font-size: 0.5rem !important;
}

.bordered-div {
  border: solid 1px inherit;
}

.border-bottom-azure {
  border-bottom: solid 2px $azure;
}

.border-bottom-c4 {
  border-bottom: solid 1px #c4c4c4;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.u-userLauncherColor:not([disabled]) {
  background: $azure !important;
}

.black-bg {
  background-color: black !important;
}

.text-70-px {
  font-size: 4.375rem;

  @include media-breakpoint-down(md) {
    font-size: 2rem;
  }

  @include media-breakpoint-between(md, lg) {
    font-size: 3.5rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 4.375rem;
  }
}

.text-size-res-300 {
  font-size: 3rem;

  @include media-breakpoint-down(md) {
    font-size: 2rem;
  }

  @include media-breakpoint-between(md, lg) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 3rem;
  }
}

.text-size-res-200 {
  font-size: 2rem;

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
  }

  @include media-breakpoint-between(md, lg) {
    font-size: 1.7rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 2rem;
  }
}

.text-150-px {
  font-size: 9.375rem;
}

.error-text {
  color: red;
}

.bg-blue-gradient {
  background: linear-gradient(
    to right,
    #45cafc,
    #05a8eb,
    #0086d7,
    #0e63be,
    #303f9f
  );
}

.bg-purple-blue {
  background-color: $purple-blue !important;
}

.border-purple-blue {
  border-color: $purple-blue !important;
}

.rounded-2 {
  border-radius: 0.5rem;
}
.rounded-10 {
  border-radius: 10px;
}
.rounded-12 {
  border-radius: 12px;
}
.rounded-27 {
  border-radius: 27px;
}
.rounded-100 {
  border-radius: 100%;
}

.pg-purple-blue {
  background-color: $purple-blue !important;
}

.clickable {
  cursor: pointer !important;
}

.list-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.25s ease;
  &:hover {
    transform: scale(8.5);
  }
}

.error-inputs {
  border-bottom: 1px solid #f44336 !important;
  box-shadow: 0 1px 0 0 #f44336 !important;
}

.scrollable {
  overflow: scroll;
}

.custom-badge {
  .badge-pill {
    border-radius: 0.5rem;
  }
}

.div-border--radius {
  border-radius: 5px;
  border: solid 1px black;
}
.overflow-scroll {
  overflow: auto;
}

//mobile responsive table
@media (max-width: 575px) {
  table {
    max-width: 100% !important;
    overflow: auto !important;
    display: block;
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.app-container {
  mdb-navbar {
    .navbar {
      .container {
        max-width: 93% !important;
      }
    }
  }

  .dropdown-header {
    padding: 0.5rem 0.5rem;
  }

  .dropdown-item {
    &:active,
    &:hover {
      background-color: linear-gradient(
        to right,
        #45cafc,
        #05a8eb,
        #0086d7,
        #0e63be,
        #303f9f
      ) !important;
      color: white !important;
    }
  }
}

.custom-select-box {
  mdb-select mdb-select-dropdown .dropdown-content li > a,
  mdb-select mdb-select-dropdown .dropdown-content {
    li {
      > span {
        text-align: justify;
        color: inherit;
      }
    }

    li.active {
      background-color: $light-grey;
      color: black !important;
    }
  }

  mdb-select > div > div.single > div.placeholder,
  div.value {
    text-align: center;
    color: #757575 !important;
  }

  mdb-select * {
    font-family: $dubai-reg;
  }
}

.custom-no-center-select-box {
  mdb-select mdb-select-dropdown .dropdown-content li > a,
  mdb-select mdb-select-dropdown .dropdown-content {
    li {
      > span {
        color: inherit;
      }
    }

    li.active {
      background-color: $light-grey;
      color: black !important;
    }
  }

  mdb-select > div > div.single > div.placeholder,
  div.value {
    color: #757575 !important;
  }

  mdb-select * {
    font-family: $dubai-reg;
  }
}

.custom-dropdown-item {
  .dropdown-item {
    &:hover,
    &:active {
      background-color: $light-grey;
      color: black !important;
    }
  }
}

.pagination .page-item.active .page-link {
  background-color: $purple-blue !important;
}

.stepper li.active a .circle,
.stepper li.completed a .circle {
  background-color: #5a1ec3 !important;
}

.order {
  mdb-select > div > div.single {
    position: relative;
    cursor: pointer;
    background-color: $purple-blue;
    border: none;
    outline: 0;
    line-height: 2rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0;
    padding: 5px 0;
    display: block;
    user-select: none;
    text-align: center;
  }

  mdb-select > div > div.single > div.value {
    color: white !important;
  }

  mdb-select > div > div.single > div.clear,
  mdb-select > div > div.single > div.toggle {
    color: white !important;
    font-size: 10px;
  }

  mdb-select {
    * {
      box-sizing: border-box;
      font-family: $dubai-reg;
      outline: none;
      color: white;
    }
  }
}

.order-pending {
  mdb-select > div > div.single {
    position: relative;
    cursor: pointer;
    background-color: $warm-grey;
    border: none;
    outline: 0;
    line-height: 2rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0;
    padding: 5px 0;
    display: block;
    user-select: none;
    text-align: center;
  }

  mdb-select > div > div.single > div.value {
    color: white !important;
  }

  mdb-select > div > div.single > div.clear,
  mdb-select > div > div.single > div.toggle {
    color: white !important;
    font-size: 10px;
  }

  mdb-select {
    * {
      box-sizing: border-box;
      font-family: $dubai-reg;
      outline: none;
      color: white;
    }
  }
}

.user-list {
  .md-tabs {
    background-color: $purple-blue !important;
  }
}

.switch.success-switch label input[type="checkbox"]:checked + .lever {
  background-color: #2acc6c;
}

.switch.success-switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #00c851;
}

.pos.switch.danger-switch label input[type="checkbox"]:checked + .lever {
  background-color: #2acc6c;
}

.pos.switch.danger-switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #00c851;
}

.pos.switch.danger-switch label input[type="checkbox"] + .lever {
  background-color: #ff606f;
}
// disabled switch style
.pos.switch.danger-switch label input[type="checkbox"][disabled] + .lever:after {
  background-color:$light-grey;
}
.pos.switch.danger-switch label input[type="checkbox"][disabled] + .lever {
  background-color:$light-grey;
}
//
.pos.switch.danger-switch label input[type="checkbox"] + .lever:after {
  background-color: #ff3547;
}

.red-active.switch.danger-switch label input[type="checkbox"]:checked + .lever {
  background-color: #ff606f;
}

.red-active.switch.danger-switch
  label
  input[type="checkbox"]:checked
  + .lever:after {
  background-color: #ff3547;
}

.red-active.switch.danger-switch label input[type="checkbox"] + .lever {
  background-color: #2acc6c;
}

.red-active.switch.danger-switch label input[type="checkbox"] + .lever:after {
  background-color: #00c851;
}

.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item:active {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: $purple-blue;
  transition: all 0.1s linear;
}

.custom-select-box
  mdb-select
  mdb-select-dropdown
  .dropdown-content
  li
  > a
  li.active,
.custom-select-box mdb-select mdb-select-dropdown .dropdown-content li.active {
  background-color: $purple-blue;
  color: white !important;
}

.modal-dialog.modal-notify.modal-purple .fa {
  color: $purple-blue;
}

table .dropdown-menu {
  z-index: 1 !important;
}

.list {
  .dropdown-toggle::after {
    display: none;
  }
}

.table-responsive {
  overflow-x: visible !important;
}

.custom-select {
  height: calc(1.5em + 0.75rem) !important;
}

mbp-popover-container {
  height: 100px !important;
  overflow-y: scroll;
}

.jc-delivery {
  .md-tabs {
    width: 600px !important;
    margin: 0 auto !important;
    margin-bottom: -20px !important;
    background-color: $purple-blue !important;
    .nav-link.active {
      background-color: rgba(182, 167, 167, 0.2);
      color: #fff;
      transition: 1s;
      border-radius: 0.125rem;
    }
  }
}

.md-form {
  .input-text--center {
    text-align: center;
  }
}

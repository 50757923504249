// colors
$purple-blue: #5a1ec3;
$netural-n100: #f6f9fc;
$netural-n400: #5a6b80;
$dark: #1c283b;
$yellow-orange: #ffb200;
$azure: #00a8e1;
$butterscotch: #fbb03b;
$aqua-marine: #2cdbda;
$white: #ffffff;
$greyish: #b2b2b2;
$purple-blue-two: #7030e8;
$dark-indigo: #33116e;
$light-indigo: #3b1480;
$green-blue: #00ca77;
$white-grey: #f8f8f8;
$warm-grey: #6f6f6f;
$bit-dark-grey: #999999;
$green-blue-2: #00cb77;
$sweet-grey: #4b515d;
$light-grey: #f5f5f5;
$red-material: #f44336;
// fonts
$dubai-reg: 'Dubai Regular';
$dubai-medium: 'Dubai Medium';
$dubai-light: 'Dubai Light';
$dubai-bold: 'Dubai Bold';

$baseFontSize: 1rem;
$baseLineHeight: 1.5;
$headingLineHeight: 1.2;
// Spacing
$pad: 1em;
